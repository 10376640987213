<template>
  <div class="services">
    <div class="services-landing">
      <img id="servicesImage" src="../assets/cover.jpeg" alt="Hvar Villas Services">
      <div class="services-landing-overlay">
        <div class="services-landing-inner">
          <h1>Hvar Villas Services</h1>
        </div>
      </div>
    </div>
    <div class="services-inner-content" v-for="service in myServices" v-bind:key="service.id">
      <div class="inner-image">
        <img v-lazy="service.image" alt="Hvar Villas Services">
      </div>
      <div class="inner-text">
        <h2>{{ service.title }}</h2>
        <div class="line"></div>
        <p class="pre-formated" v-html="service.content"></p>

        <button class="services-button" @click="show()">Make Enquiry</button>
      </div>
    </div>

  </div>

</template>

<script>
import services from '../data/services.json'
import Modal from "@/components/Modal"


export default {

  metaInfo() {
    return {
      title: 'Check now Hvar Villas For Rent | Hvar Villas services',
      meta: [
        { name: "robots", content: "index,follow" },
        {
          name: 'description',
          content: 'Do you search Hvar villas for rent?  we are here for you. Hvar Villas services will level up your experience.'
        }

      ],
      link: [
        {rel: 'favicon', href: 'favicon.ico'}

      ]
    }
  },
  data() {
    return {
      myServices: services,
      loaded: false
    }
  },
  methods: {
    onLoaded() {
      this.loaded = true;
    },

    show() {
      this.$modal.show(
          Modal,
          
      );
    },
  }


}
</script>